import React from "react";
import "./Paragraph.scss";

export default function TextList({
  list = [],
  appearance = "normal",
  ...props
}) {
  return (
    <ul
      className={`paragraph paragraph--list paragraph--${appearance}`}
      {...props}
    >
      {list.map((el) => (
        <li>{el}</li>
      ))}
    </ul>
  );
}
