import React from "react";
import "./ToolsCollageWhatWeDo.scss";
import WrapperModalVideo from "./WrapperModalVideo";
import Picture from "./Picture";
import toolsPhone from "assets/images/tools-what-we-do/welcome_white_2.png";
import toolsPhoneW from "assets/images/tools-what-we-do/welcome_white_2.webp";
import toolsAppStore from "assets/images/tools-what-we-do/loanpal_pro_app_store.png";
import toolsAppStoreW from "assets/images/tools-what-we-do/loanpal_pro_app_store.webp";
import toolsTimescreen from "assets/images/tools-what-we-do/app.png";
import toolsTimescreenW from "assets/images/tools-what-we-do/app.webp";
import toolsTimeScreenTwo from "assets/images/tools-what-we-do/loan_time_screen_2.jpg";
import toolsTimeScreenTwoW from "assets/images/tools-what-we-do/loan_time_screen_2.webp";
import toolsVideo from "assets/images/tools-what-we-do/tony.png";
import toolsVideoW from "assets/images/tools-what-we-do/tony.webp";
import CardStore from "./CardStore";

export default function ToolsCollageWhatWeDo() {
  const collageImages = [
    {
      videoId: "zDUAc0xi5B8",
      image: toolsVideo,
      webp: toolsVideoW,
      alt: "toolsVideo",
    },
    {
      image: toolsPhone,
      webp: toolsPhoneW,
      video: false,
      alt: "toolsPhone",
    },

    {
      image: toolsTimescreen,
      webp: toolsTimescreenW,
      video: false,
      alt: "toolsTimescreen",
    },
    {
      image: toolsAppStore,
      webp: toolsAppStoreW,
      video: false,
      alt: "toolsAppStore",
    },
    {
      image: toolsTimeScreenTwo,
      webp: toolsTimeScreenTwoW,
      video: false,
      alt: "toolsTimescreenTwo",
    },
  ];

  return (
    <div className="toolscollage_whatwedo">
      {collageImages.map((singleImage, index) => (
        <div key={`collage-tool-${index}`}>
          {singleImage?.videoId && (
            <div className={`toolscollage__whatwedo_picture--${index}`}>
              <WrapperModalVideo videoId={singleImage.videoId} showButton>
                <Picture
                  src={singleImage.image}
                  webp={singleImage.webp}
                  alt="We are mission-driven"
                />
              </WrapperModalVideo>
            </div>
          )}
          {!singleImage?.videoId && (
            <picture className={`toolscollage__whatwedo_picture--${index}`}>
              <source
                srcSet={singleImage.webp}
                alt={singleImage.alt}
                type="image/webp"
              />
              <source
                srcSet={singleImage.image}
                alt={singleImage.alt}
                type="image/jpeg"
              />
              <img src={singleImage.image} alt={singleImage.alt} />
            </picture>
          )}

          <CardStore appearance="toolscollage__whatwedo_picture--5" />
        </div>
      ))}
    </div>
  );
}
