import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";

import { schema, resolver } from "data/form-schemas/contact.js";
import { sendContactForm } from "services/index.js";

import "./ContactForm.scss";
import "./InputText.scss";

export default function ContactForm({ emailTo = "", emailToCopy = false }) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({ name, phone, emailFrom, address }) => {
    setSubmited(true);

    const formData = {
      name,
      emailFrom,
      phone,
      address,
      emailTo: emailTo,
      subject: `Loanpal inquiry from ${name}`,
    };

    try {
      const response = await sendContactForm(formData);
      if (!emailToCopy) {
        setFormStatus("SENT");
      }
    } catch (err) {
      setFormStatus("ERROR");
    }

    if (emailToCopy) {
      try {
        const response = await sendContactForm({
          ...formData,
          emailTo: emailToCopy,
        });
        setFormStatus("SENT");
      } catch (err) {
        setFormStatus("ERROR");
      }
    }

    setSubmited(false);
  };

  return (
    <>
      {emailTo.length && (
        <div className="contact-form">
          {formStatus === "SENT" && <FormSuccess />}

          {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

          {formStatus === "WAIT" && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>
                Simply enter your information below and one of our friendly team
                members will be in touch shortly.
              </h2>

              <p className="disclaimer">
                Required fields <span>*</span>
              </p>

              <InputText
                {...schema.name}
                register={register}
                errors={errors["name"]}
                value={watch("name")}
                isRequired={schema["name"].validation.exclusiveTests.required}
              />

              <InputText
                {...schema.phone}
                register={register}
                errors={errors["phone"]}
                value={watch("phone")}
                isRequired={schema["phone"].validation.exclusiveTests.required}
              />

              <InputText
                {...schema.emailFrom}
                register={register}
                errors={errors["emailFrom"]}
                value={watch("emailFrom")}
                isRequired={
                  schema["emailFrom"].validation.exclusiveTests.required
                }
              />

              <InputText
                {...schema.address}
                register={register}
                errors={errors["address"]}
                value={watch("address")}
                isRequired={
                  schema["address"].validation.exclusiveTests.required
                }
              />

              <FormLegals />

              <FormSubmit data-emailto={emailTo} disabled={submited} />
            </form>
          )}
        </div>
      )}
    </>
  );
}
