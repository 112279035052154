import React from "react";
import "./SolarCollage.scss";

import solarPhone from "assets/images/what-we-do/woman_calling.png";
import sale from "assets/images/what-we-do/sale.png";
import solarPanel from "assets/images/what-we-do/solarpanel.png";
import solarDrill from "assets/images/what-we-do/drill.png";
import solarTable from "assets/images/what-we-do/table.jpg";
import solarStock from "assets/images/what-we-do/roof.jpg";
import VideoCard from "./VideoCard";

export default function SolarCollage() {
  const collageImages = [
    {
      id: "SUdSIRsqzIA",
      picture: solarTable,
      videoLink: false,
      pictureAlt: "solarVideo",
    },
    { picture: solarPhone, videoLink: false, pictureAlt: "toolsPhone" },

    {
      picture: sale,
      videoLink: false,
      pictureAlt: "solarPicture",
    },
    {
      picture: solarPanel,
      videoLink: false,
      pictureAlt: "solarPicture",
    },
    {
      picture: solarDrill,
      videoLink: false,
      pictureAlt: "solarPicture",
    },
    {
      picture: solarStock,
      videoLink: false,
      pictureAlt: "solarPicture",
    },
  ];

  return (
    <div className="solarcollage">
      {collageImages.map((singleImage, index) => (
        <div key={`collage-solar-${index}`}>
          {singleImage?.videoLink && (
            <VideoCard {...singleImage} appearance="solar-video" />
          )}
          {!singleImage?.videoLink && (
            <picture
              className={`solarcollage__picture solarcollage__picture--${index}`}
            >
              <source
                srcSet={singleImage.picture}
                alt={singleImage.pictureAlt}
                type="image/jpeg"
              />
              <img src={singleImage.picture} alt={singleImage.pictureAlt} />
            </picture>
          )}
        </div>
      ))}
    </div>
  );
}
