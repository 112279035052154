import React from "react";
import Picture from "components/Picture";
import MediaContainer from "components/MediaContainer";

import videoPartners from "assets/images/what-we-do/loanpal_video.jpg";
import videoPartnersW from "assets/images/what-we-do/loanpal_video.webp";
import "./SolarPartners.scss";
import WrapperModalVideo from "./WrapperModalVideo";
export default function SolarPartners() {
  return (
    <div className="solar-partners__container">
      <div className="solar-partners__skiew"></div>
      <div className="solar-partners__content">
        <h3 className="solar-partners__title">See why our partners love us</h3>
        <div className="solar-partners__picture">
          <WrapperModalVideo videoId="fQFYwj1zwKw" showButton={true}>
            <MediaContainer>
              <Picture
                src={videoPartners}
                webp={videoPartnersW}
                alt="Partners"
              />
            </MediaContainer>
          </WrapperModalVideo>
        </div>
      </div>
    </div>
  );
}
