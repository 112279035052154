import React from "react";
import VideoPopupWrapper from "../components/VideoPopupWrapper";
import WrapperModalVideo from "../components/WrapperModalVideo";
import PlayIcon from "../assets/images/wol/play_icon.png";
import Background from "../assets/images/wol/background-orange.svg";
import "./VideoCard.scss";
export default function VideoCard({
  id,
  title,
  description,
  episode,
  picture,
  pictureAlt,
  videoLink,
  appearance = false,
}) {

  return (
    <div className={`WL_VideoCard WL_VideoCard--${appearance} `}>
      <WrapperModalVideo videoId={id} className="WL_VideoLink">
        <div className={`WL_PictureWrapper  WL_PictureWrapper--${appearance}`}>
          <img src={picture} alt={pictureAlt} className="WL_Picture" />

          {videoLink !== "" && (
            <img src={PlayIcon} alt={pictureAlt} className={`WL_PlayIcon  WL_PlayIcon--${appearance}`}/>
          )}
        </div>
        {appearance==="card"  && (
          <div
            className="WL_TextWrapper"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <h2 className="WL_VideoId">Watch Episode {episode}: </h2>
            <h3 className="WL_VideoTitle">"{title}"</h3>
            <p className="WL_VideoDescription">{description}</p>
          </div>
        )}
      </WrapperModalVideo>
    </div>
  );
}
