import React from "react";

import "./CardSignUp.scss";
import Background from "assets/images/tools-what-we-do/new_solar_panel.png";

export default function CardSignUp({ type, children, ...props }) {
  return (
    <div className={`card-signup__container`} {...props}>
      <img src={Background} className="card-signup-img" alt="Solar panels" />
      <div className={`card-signup ${type && "card-signup--" + type}`}>
        {children}
      </div>
    </div>
  );
}
