import React, { useState } from "react";
import { Link } from "gatsby";
import { Modal } from "react-responsive-modal";

import { useIsLargeMobileAndBelow } from "hooks/breakpoints";

import Main from "components/layout/Main";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";

import PointerParent from "components/PointerParent";

import PartnersImg from "assets/images/what-we-do/table.jpg";
import PartnersImgWebp from "assets/images/what-we-do/table.webp";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import Picture from "components/Picture";
import Button from "components/Button";
import SolarCollage from "components/SolarCollage";
import SolarElement from "components/SolarElement";
import { elements } from "data/what-we-do/solarElements.js";
import CardSignUp from "components/CardSignUp";
import MediaContainer from "components/MediaContainer";
import ContentGrid from "components/ContentGrid";
import ToolsCollageWhatWeDo from "components/ToolsCollageWhatWeDo";
import TextList from "components/TextList";

import iconTransfer from "assets/images/what-we-do/icon_transfer_funds.png";
import iconTransferW from "assets/images/what-we-do/icon_transfer_funds.webp";

import backgroundCover from "assets/images/header_skew_video/tami_dusk.jpg";
import backgroundCoverMobile from "assets/images/header_skew_video/tami_dusk_mobile.jpg";

import ParagraphPointer from "components/ParagraphPointer";
import SolarPartners from "components/SolarPartners";
import SolarRectangle from "components/SolarRectangle";
import PartnerIntakeForm from "components/forms/PartnerIntakeForm";
import SolarCustomerIntakeForm from "components/forms/SolarCustomerIntakeForm";

import GenericForm from "components/forms/GenericForm";
import ScrollToTop from "components/ScrollToTop";

export default function WhatWeDoPage() {
  const [openFormPartnerIntake, setOpenFormPartnerIntake] = useState(false);
  const [openFormSolarCustomer, setOpenFormSolarCustomer] = useState(false);

  const [openFormImprovement, setOpenFormImprovement] = useState(false);
  const [openFormRefinance, setOpenFormRefinance] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const isMobile = useIsLargeMobileAndBelow();

  const points = ["headingslim1"];

  return (
    <>
      <Main page={"what-we-do"} pagetitle="Partners">
        <HeaderSkew
          title="Driving sustainability forward, one house at a time"
          background={backgroundCover}
          background={isMobile ? backgroundCoverMobile : backgroundCover}
          url="what-we-do"
          backgroundColor="tami"
          appearance="grid-gray"
        />
        <PointerParent points={points}>
          <Block layer="1" guides={1} appearance="regular-face">
            <ContentGrid appearance="main-single">
              <div>
                <Heading type="h2" appearance="h1--dark" id="headingslim1">
                  By joining the Loanpal platform, you can:
                </Heading>
                <TextList
                  list={[
                    "Grow your business and increase sales by as much as 20%+.",
                    "Expand your market with flexible payment options, greater financing amounts, higher approval rates and co-borrower options.",
                    "Enjoy simple, frictionless tech that delivers approvals in seconds with the touch of a button from any mobile device.",
                  ]}
                />

                <Button
                  handleClick={() => setOpenFormPartnerIntake(true)}
                  appearance="slim-ghost"
                  aria-label="Open form"
                  caret
                >
                  Become a partner
                </Button>
              </div>
            </ContentGrid>
          </Block>
          <Block
            layer="2"
            guides={1}
            appearance="regular"
            style={{ overflow: "visible" }}
          >
            <SolarPartners />
          </Block>

          <Block
            layer="3"
            name="Tools to help"
            guides="1"
            appearance="grid-gray-tools"
          >
            <ContentGrid appearance="tools-solar">
              <div id="t3">
                <div data-aos="fade-up">
                  <Heading type="h2" appearance="h1--dark-small " id="wwd1">
                    The fastest, smoothest way to help homeowners upgrade their
                    homes sustainably
                  </Heading>

                  <Paragraph appearance="small-plus-bottom">
                    Our growing suite of products and tools allow you to make
                    real-time updates, compare rates and term details, all from
                    the convenient touch of your mobile device.
                  </Paragraph>
                </div>
              </div>
              <div />
              <ToolsCollageWhatWeDo
                openVideo={() => onOpenModal("video-three")}
                data-aos="fade-in"
              />
            </ContentGrid>
          </Block>
          {/* */}
          <Block layer="1" guides={1} appearance="grid-simple-bottomless">
            <ContentGrid
              appearance="partners"
              style={{ zIndex: 10, position: "relative" }}
            >
              <MediaContainer appearance="offset-3">
                <Picture
                  src={PartnersImg}
                  webp={PartnersImgWebp}
                  alt="America’s #1 way to pay for solar"
                />
              </MediaContainer>

              <div data-aos="fade-up">
                <Heading type="h2" appearance="h1--dark" id="wwd0">
                  America’s #1 way to pay for solar
                </Heading>
                <Paragraph appearance="small-plus">
                  With over 400 top-tier solar brands on our platform, we’ve
                  helped our partners grow their business and increase
                  conversion rates by as much as 30%. We make it fast and
                  frictionless for customers everywhere.
                </Paragraph>
                <Button
                  handleClick={() => setOpenFormPartnerIntake(true)}
                  appearance="slim-ghost"
                  aria-label="Open form"
                  caret
                >
                  Become a partner
                </Button>
              </div>
              <div></div>
              <SolarCollage />
            </ContentGrid>

            {/* */}
            <CardSignUp
              type="in-header"
              style={{ zIndex: 0, position: "relative" }}
            >
              <Picture
                src={iconTransfer}
                webp={iconTransferW}
                width="82"
                height="82"
                alt="Icon Transfer"
              />
              <h2 className="card-signup-title">
                Solar Partners - sign up today for our Direct Pay Program
              </h2>
              <p>
                We’ve partnered with top distributors across the country to
                create a program where we pay them directly for equipment, so
                you can unleash more capital to grow your business. Plus, you’ll
                be able to join Loanpal’s industry-leading platform, and enjoy
                the benefits of fast, frictionless financing.
              </p>

              <Link className="button button--slim-ghost" to="/direct-pay">
                Learn How
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.621"
                  height="14.242"
                  viewBox="0 0 8.621 14.242"
                  role="img"
                >
                  <path
                    d="M-16464.961-2813.023l3.75,3.75,1.25,1.25-5,5"
                    transform="translate(16467.082 2815.144)"
                    fill="none"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </Link>
            </CardSignUp>
            {/**/}

            {/**/}
          </Block>
          <Block
            layer="2"
            guides={2}
            appearance="white-gray-block"
            guideType="center-desktop"
          >
            <SolarRectangle
              text="We are a digital conduit connecting the world’s growing sustainability marketplaces"
              index={1}
            />
          </Block>
          <div className="pointer-parent ">
            {elements
              .filter(({ enabled }) => enabled)
              .map((el, index) => (
                <ParagraphPointer
                  key={`solar-pointer-element-${index}`}
                  linkedTo={`#wwd-se${index}`}
                />
              ))}

            <Block
              layer="1"
              name="About Us"
              guides={2}
              guideType="center-left-mobile"
              appearance="grid-solar-cards"
            >
              {elements
                .filter(({ enabled }) => enabled)
                .map((element, index) => (
                  <SolarElement
                    key={`solar-element-${index}`}
                    {...element}
                    a
                    isEven={index % 2}
                    id={`wwd-se${index}`}
                  />
                ))}
            </Block>
          </div>
          <SolarRectangle
            text="Sustainable <br/> Home Improvement <br/> Professionals"
            subText=" Discover the many ways we can help upgrade homes sustainably"
            linkText="Learn More"
            index={2}
            appearance="gray-white"
            onClick={() => setOpenFormImprovement(true)}
          />
        </PointerParent>
        <ScrollToTop guides={0} />
      </Main>

      <Modal
        open={openFormPartnerIntake}
        onClose={() => setOpenFormPartnerIntake(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <PartnerIntakeForm emailTo="partnerapproval@loanpal.com" />
      </Modal>
      <Modal
        open={openFormSolarCustomer}
        onClose={() => setOpenFormSolarCustomer(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <SolarCustomerIntakeForm />
      </Modal>

      <Modal
        open={openFormImprovement}
        onClose={() => setOpenFormImprovement(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <>
          <PartnerIntakeForm emailTo="HIFinancing@loanpal.com" />
        </>
      </Modal>

      <Modal
        open={openFormRefinance}
        onClose={() => setOpenFormRefinance(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <GenericForm emailTo="mmurray@loanpal.com" />
      </Modal>
    </>
  );
}
